<template>
  <div class="level-item" :class="renderClass" >
    <div>
      <p class="heading">{{ heading }}</p>
      <p class="title" :class="titleClass">{{ title }}</p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  props: {
    customClass: {
      type: String,
      default: '' // is-medium, is-large
    },
    titleClass: {
      type: String,
      default: '' // is-medium, is-large
    },
    heading: {
      type: String,
      default: '', // is-medium, is-large
      required: true
    },
    title : {
      type: [String, Number],
      default: ''
    }
  },
  setup (props) {
    const renderClass = ref(props.customClass)
    return {
      renderClass
    }
  }
}
</script>

<style lang="scss" scoped>

</style>